<style lang="scss">

</style>
<!--eslint-disable-->
<template>
  <div class="enterpise-apps-page" v-if="!loading">
    <b-card>
      <b-card-body class="full-width d-inline-flex align-items-center pa-0 px-0 py-0">
        <h2>{{ organisation.name }}</h2>
        <div class="ui-spacer"></div>
        <b-button @click="switchOrganisation()" class="d-flex ml-auto btn-outline">Switch</b-button>
      </b-card-body>
    </b-card>
  </div>
</template>
<!--eslint-enable-->

<script>
import auth from '@/auth/auth'
import OrganisationService from '@/services/OrganisationService';

export default {
  name: 'Organisation',
  components: {
  },
  props: {
    uid: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      organisation: null,
      loading: true,
    };
  },
  mounted() {
    this.getOrganisation();
  },
  methods: {
    switchOrganisation() {
      auth.switch({
        organisation_uid: this.uid,
      }).then(response => {
          // Make sure they have some permissions
          if (response.data.userData.permissions == null) {
            this.loginError = 'You do not have the right Role or Permissions. Please contact your Site Administrator'
            return
          }
          auth.setToken(response.data.accessToken)
          auth.setCurrentUser(response.data.userData)
          auth.refreshToken()
          this.$ability.update(response.data.userData.permissions)
          this.$toast.success(`Successfully switched to organisation ${response.data.userData.organisation_name}`, {
            toastClassName: ['toast-std', 'success-toast'],
          });
        })
        .catch(() => {
          this.$toast.error('Could not set organisation, please refresh and try again', {
            toastClassName: ['toast-std', 'warning-toast'],
          });
        })
        .finally(() => {
          this.loading = false;
      })
    },
    getOrganisation() {
      this.loading = true;
      OrganisationService.getOrganisation(this.uid).then(res => {
        this.organisation = res.data;
      }).catch(() => {
        this.$toast.error('Could not get organisation, please refresh and try again', {
          toastClassName: ['toast-std', 'warning-toast'],
        });
      }).finally(() => {
        this.loading = false;
      });
    },
  },
};
</script>
