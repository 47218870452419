import axios from '@axios';
import HelperService from '@/services/HelperService'

const baseUrl = '/organisation';

export default {
  getOrganisations(filters) {
    const queryString = HelperService.buildSearchQueryString(filters);
    return axios.get(`${baseUrl}${queryString}`);
  },
  getOrganisation() {
    return axios.get(`${baseUrl}`);
  },
  updateOrganisation(organisation) {
    return axios.patch(`${baseUrl}`, organisation);
  },
  getEnterpriseWebToken(uid) {
    return axios.get(`${baseUrl}/${uid}/token`);
  },
};
